.app {
  display: flex;
  flex-direction: column;
}

.app header {
  display: flex;
  flex-direction: row;
  background-color: #333;
  color: white;
  align-items: center;
  padding: 0.25em;
}

.app header a:active, .app  header  a:hover, .app header  a {
  color: white;
  flex: 1;
  text-decoration: none;
}

.app header h1 {
  margin: 0.25em 0 0.3em 0.3em ;
}

.app header button {
  margin: 0 0.5em;
}

.padded {
  padding: 0.5em;
}


.error {
  padding: 1em;
  border: 1px solid #f33;
  color: #F33;
  background: #FCC;
  margin: 1em;
}

.error h4 {
  margin: 0;
}